<template>
  <div class="mt-6 p-4 bg-gray-50 rounded text-left">
    <p class="font-semibold">
      ¿Desea deshabilitar el tarifario o las unidades de captura?
    </p>
    <p class="mb-3 max-w-lg">
      Aquí podrás deshabilitar el tarifario o eliminar las unidades de captura.
    </p>
    <Button
      type="button"
      :danger="true"
      :invert="true"
      size="sm"
      @click.native="showModal = true"
    >
      Deshabilitar
    </Button>

    <div>
      <a-modal
        :visible="showModal"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        :footer="null"
      >
        <div class="flex flex-col py-4 gap-6">
          <div>
            <h3 class="mb-2 text-lg font-bold">Deshabilitar tarifario</h3>
            <div class="p-4 bg-red-100 bg-opacity-50 rounded-lg shadow">
              <p class="mb-1 font-semibold text-red-700">¡Advertencia!</p>
              <p class="mb-1 text-sm max-w-lg text-gray-500">
                1. En caso de no seleccionar niguna opción se deshabilitará el
                tarifario por completo junto con todas sus unidades de captura.
                En caso de querer reactivarlo, podrá hacerlo manteniendo el
                encabezado y creando nuevas unidades de captura.
              </p>

              <p class="mb-1 text-sm max-w-lg text-gray-500">
                2. Al seleccionar una opción se deshabilitaran las unidades de
                captura que cumplan con el criterio seleccionado.
              </p>
            </div>
          </div>
          <div class="-mt-6 grid grid-cols-1">
            <BaseSelect
              v-if="isCreditCardOrDeposit"
              label="Operación o servicio"
              :selectOptions="operationOrServiceOptions"
              v-model="form.operacion_servicio"
            />
            <BaseSelect
              v-if="isCreditCardOrDeposit"
              label="Canal"
              :selectOptions="formOptions.optionsTariffChannel"
              v-model="form.canal"
            />
            <BaseSelect
              v-if="isCredit(formatType)"
              label="Características del crédito"
              :selectOptions="formOptions.optionsOperationOrServiceCredit"
              v-model="form.caracteristica_credito"
            />
          </div>
          <!-- <p v-if="feedback" class="text-center text-red-500">{{ feedback }}</p> -->
          <div class="flex flex-col-reverse md:flex-row gap-5">
            <Button
              :danger="true"
              :block="true"
              :invert="true"
              @click.native="handleCancel"
            >
              Cancelar
            </Button>
            <Button
              :danger="true"
              :block="true"
              @click.native="handleOk"
              :loading="confirmLoading"
              :disable="confirmLoading"
            >
              Deshabilitar
            </Button>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import formMixin from '@/components/Dashboard/Tariffs/FormTariff/formMixin'
export default {
  props: {
    tariff: {
      type: Object,
      default: null,
    },
    formatType: {
      type: Number,
      default: null,
    },
  },
  mixins: [formMixin],
  data() {
    return {
      showModal: false,
      confirmLoading: false,
      form: {
        operacion_servicio: '',
        canal: '',
        caracteristica_credito: '',
      },
    }
  },
  computed: {
    ...mapState({
      formOptions: 'form',
    }),
    ...mapGetters('tariffs', ['isDeposit', 'isCreditCard', 'isCredit']),
    channelOptions() {
      return []
    },
    operationOrServiceOptions() {
      if (this.isDeposit(this.formatType)) {
        return this.formOptions.optionsOperationOrService
      } else if (this.isCreditCard(this.formatType)) {
        return this.formOptions.optionsOperationOrServiceCreditCard
      }
      return []
    },
    isCreditCardOrDeposit() {
      return (
        this.isCreditCard(this.formatType) || this.isDeposit(this.formatType)
      )
    },
  },
  methods: {
    async handleOk() {
      if (this.invalidForm()) return
      this.confirmLoading = true

      await this.disableTariffData(this.form, this.formatType)
      this.confirmLoading = false
      this.showModal = false
    },
    handleCancel() {
      this.showModal = false
    },
    invalidForm() {
      this.feedback = null
      return false
    },
  },
}
</script>
