import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    myVariable: 10,
  }),
  methods: {
    ...mapActions('tariffs', [
      'createTariff',
      'updateTariff',
      'fetchTariff',
      'disableTariff',
    ]),
    updateForm() {
      this.$emit('change', this.form)
    },
    deleteCaptureUnit(index) {
      this.form.unidad_captura.find
      const newList = [...this.form.unidad_captura]
      newList.splice(index, 1)
      this.form.unidad_captura = newList
    },
    async createTariffData() {
      let formData = this.form
      this.loading = true
      let response = await this.createTariff({
        formatType: this.formatType,
        formData,
      })
      this.loading = false
      if (!response.success) {
        this.showBackErrors(response.data)
        return
      }
      // TODO eliminar
      console.log(response)
      this.$showNotification('success', 'Se creó correctamente el tarifario ')
      this.$router.go(-1)
    },
    async updateTariffData() {
      let formData = this.form
      const { tariff_id } = this.$route.params
      this.loading = true
      let response = await this.updateTariff({
        id: tariff_id,
        formatType: this.formatType,
        formData,
      })
      this.loading = false
      if (!response.success) {
        this.showBackErrors(response.data)
        return
      }
      this.$router.go(-1)
    },
    async disableTariffData(form, formatType) {
      let formData = form
      const { tariff_id } = this.$route.params
      this.loading = true
      let response = await this.disableTariff({
        id: tariff_id,
        formatType: formatType,
        formData,
      })
      this.loading = false
      if (!response.success) {
        this.showBackErrors(response.data)
        return
      }
      this.$router.go(-1)
    },
    async fetchTariffData() {
      this.loading = true
      const { tariff_id } = this.$route.params
      let response = await this.fetchTariff({
        formatType: this.formatType,
        id: tariff_id,
      })
      this.loading = false
      if (!response) return
      Object.keys(this.form).forEach((key) => {
        this.form[key] = response.data.result[0][key]
      })
    },
    showBackErrors(errors) {
      if (!Array.isArray(errors)) {
        this.$showNotification('error', JSON.stringify(errors.message))
        return
      }

      this.$showNotification('error', 'Verifique los campos')

      for (let i = 0; errors.length > i; i++) {
        const error = errors[i]
        const { field, message } = errors[i]
        if (!field) {
          this.$showNotification('error', errors[i])
          return
        }

        if (field === 'unidad_captura') {
          for (let j = 0; error.errors.length > j; j++) {
            let unitsCapture = error.errors[j]

            for (let k = 0; unitsCapture.length > k; k++) {
              const ref = `${unitsCapture[k].field}-${j}`
              if (this.$refs[ref]) {
                this.$refs[ref][0].messageError = unitsCapture[k].message
              }
            }
          }
          return
        }
        if (this.$refs[field]) {
          this.$refs[field].messageError = message
        }
      }
    },
  },
  mounted() {
    if (this.editView || this.detailsView) {
      this.fetchTariffData()
    }
  },
  computed: {
    ...mapState({
      formOptions: 'form',
    }),
  },
  watch: {
    form: {
      handler() {
        this.updateForm()
      },
      deep: true,
    },
  },
}
