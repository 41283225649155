<template>
  <form @submit.prevent="createTariffData">
    <!-- HEADER -->
    <div
      v-if="title || description"
      class="flex justify-between items-center mt-3 text-left"
    >
      <div>
        <h2 class="text-lg">
          <b>{{ title }}</b>
        </h2>
        <p class="text-gray-500">{{ description }}</p>
      </div>
      <StatusLabel v-if="detailsView || editView" :status="form.activo" />
    </div>
    <div>
      <BaseInput
        type="number"
        label="Código de Registro"
        v-model="form.codigo_registro"
        ref="codigo_registro"
        placeholder=""
        autocomplete="off"
        :disabled="detailsView"
      />

      <BaseInput
        type="text"
        label="Nombre comercial"
        v-model="form.nombre_comercial"
        ref="nombre_comercial"
        placeholder=""
        autocomplete="off"
        :required="true"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Tipo producto de depósito"
        :selectOptions="formOptions.optionsDepositProduct"
        v-model="form.tipo_producto"
        ref="tipo_producto"
        :required="true"
        :disabled="detailsView"
      />
      <BaseSelect
        label="Apertura Digital"
        :selectOptions="formOptions.optionsDigitalOpening"
        v-model="form.apertura_digital"
        ref="apertura_digital"
        :disabled="detailsView"
      />
      <BaseInput
        type="number"
        label="Número de clientes únicos"
        v-model="form.num_clientes_unicos"
        ref="num_clientes_unicos"
        placeholder=""
        :disabled="detailsView"
      />
      <BaseInput
        type="number"
        label="Cuota de manejo"
        v-model="form.cuota_manejo"
        ref="cuota_manejo"
        placeholder=""
        autocomplete="off"
        :required="true"
        :disabled="detailsView"
      />
      <BaseSelect
        label="Observaciones cuota de manejo"
        :selectOptions="formOptions.optionsObservationManagmentFeeDeposit"
        v-model="form.obs_cuota_manejo"
        ref="obs_cuota_manejo"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Grupo poblacional"
        :selectOptions="formOptions.optionsPopulationGroup"
        v-model="form.grupo_poblacional"
        ref="grupo_poblacional"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Ingresos"
        :selectOptions="formOptions.optionsIncome"
        v-model="form.ingresos"
        ref="ingresos"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Cuenta de ahorros 1"
        :selectOptions="formOptions.optionsSavingAccount"
        v-model="form.cuenta_ahorros_1"
        ref="cuenta_ahorros_1"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Cuenta de ahorros 2"
        :selectOptions="formOptions.optionsSavingAccount"
        v-model="form.cuenta_ahorros_2"
        ref="cuenta_ahorros_2"
        :disabled="detailsView"
      />
      <BaseSelect
        label="Cuenta de ahorros 3"
        :selectOptions="formOptions.optionsSavingAccount"
        v-model="form.cuenta_ahorros_3"
        ref="cuenta_ahorros_3"
        :disabled="detailsView"
      />
      <BaseSelect
        label="Tarjeta débito 1"
        :selectOptions="formOptions.optionsDebitCard"
        v-model="form.tarjeta_debito_1"
        ref="tarjeta_debito_1"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Tarjeta débito 2"
        :selectOptions="formOptions.optionsDebitCard"
        v-model="form.tarjeta_debito_2"
        ref="tarjeta_debito_2"
        :disabled="detailsView"
      />
      <BaseSelect
        label="Tarjeta débito 3"
        :selectOptions="formOptions.optionsDebitCard"
        v-model="form.tarjeta_debito_3"
        ref="tarjeta_debito_3"
        :disabled="detailsView"
      />
    </div>
    <!-- CAPTURA UNIT -->
    <div
      v-if="!!form.unidad_captura.length || !detailsView"
      class="mt-6 mb-4 text-left"
    >
      <h2 class="text-lg"><b>Unidades de captura</b></h2>
      <p class="text-gray-500">
        Ingrese la información de la unidad de captura
      </p>
    </div>
    <div
      v-if="!!form.unidad_captura.length || !detailsView"
      class="p-4 bg-gray-50 rounded-lg"
    >
      <div v-if="!detailsView" class="mb-3 flex justify-end">
        <Button type="button" styles="secondary" @click.native="addCaptureUnit">
          Agregar unidad de captura
        </Button>
      </div>
      <div
        v-for="(unit, index) in form.unidad_captura"
        :key="`uk-${index}`"
        class="px-3 pb-6 mb-3 flex flex-col gap-3 border rounded-lg bg-white"
      >
        <BaseInput
          type="text"
          label="Código de registro de unidad de captura"
          v-model="unit.codigo_registro_uc"
          :ref="`codigo_registro_uc-${index}`"
          placeholder=""
          autocomplete="off"
          :disabled="detailsView"
        />
        <BaseSelect
          label="Operación o servicio"
          :selectOptions="formOptions.optionsOperationOrService"
          v-model="unit.operacion_servicio"
          :ref="`operacion_servicio-${index}`"
          :disabled="detailsView"
        />
        <BaseSelect
          label="Canal"
          :selectOptions="formOptions.optionsTariffChannel"
          v-model="unit.canal"
          :ref="`canal-${index}`"
          :disabled="detailsView"
        />
        <BaseInput
          type="number"
          label="Número de operaciones o servicios incluidos en cuota de manejo"
          v-model="unit.num_oper_serv_cuota_manejo"
          :ref="`num_oper_serv_cuota_manejo-${index}`"
          placeholder=""
          autocomplete="off"
          :disabled="detailsView"
        />
        <BaseInput
          type="number"
          label="Costo fijo"
          v-model="unit.costo_fijo"
          :ref="`costo_fijo-${index}`"
          placeholder=""
          autocomplete="off"
          :disabled="detailsView"
        />
        <BaseInput
          type="number"
          label="Costo proporcional a operación o servicio"
          v-model="unit.costo_prop_oper_serv"
          :ref="`costo_prop_oper_serv-${index}`"
          placeholder=""
          autocomplete="off"
          :disabled="detailsView"
        />

        <BaseSelect
          label="Observaciones"
          :selectOptions="formOptions.optionsObservationDeposit"
          v-model="unit.observaciones"
          :ref="`observaciones-${index}`"
          :disabled="detailsView"
        />
        <div
          v-if="!detailsView && form.unidad_captura.length > 0 && !unit.activo"
          class="flex justify-end"
        >
          <Button
            type="button"
            styles="secondary"
            :invert="true"
            @click.native="deleteCaptureUnit(index)"
          >
            Eliminar
          </Button>
        </div>
      </div>
    </div>
    <!-- Start Disable Section -->
    <DisableTariff
      v-if="editView && form.desactivable"
      :format-type="formatType"
    />
    <!-- End disable section -->
    <div v-if="feedback">
      <p class="mt-8 text-xs text-center text-red-500">{{ feedback }}</p>
    </div>
    <div
      class="w-full mt-8 mr-auto flex flex-col lg:flex-row-reverse lg:justify-end gap-4"
    >
      <Button
        v-if="detailsView"
        type="button"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
        @click.native="
          $router.replace({ path: 'edit', append: true, query: $route.query })
        "
      >
        Editar
      </Button>
      <Button
        v-if="editView"
        type="button"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
        @click.native="updateTariffData"
      >
        Actualizar
      </Button>
      <Button
        v-if="!editView && !detailsView"
        type="submit"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
      >
        Crear
      </Button>

      <Button
        type="button"
        :danger="true"
        :invert="true"
        :block="true"
        :disable="loading"
        @click.native="$router.go(-1)"
      >
        Volver
      </Button>
    </div>
  </form>
</template>
<script>
import {
  DEPOSIT_HEADER,
  DEPOSIT_UNIT_CAPTURE,
} from '@/components/Dashboard/Tariffs/objects/tariffProduct.js'
import formMixin from '@/components/Dashboard/Tariffs/FormTariff/formMixin'
import DisableTariff from '@/components/Dashboard/Tariffs/FormTariff/DisableTariff.vue'
import StatusLabel from '@/components/Dashboard/Tariffs/FormTariff/StatusLabel.vue'
export default {
  props: {
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
    detailsView: {
      default: false,
      type: Boolean,
    },
    editView: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      feedback: null,
      formatType: 1,
      form: {
        ...JSON.parse(JSON.stringify(DEPOSIT_HEADER)),
      },
    }
  },
  methods: {
    addCaptureUnit() {
      this.form.unidad_captura.unshift({ ...DEPOSIT_UNIT_CAPTURE })
    },
  },
  components: { DisableTariff, StatusLabel },
}
</script>
