<template>
  <div class="flex justify-end items-center gap-2">
    <p class="font-semibold text-gray-600">Estado:</p>
    <span
      class="px-3 text-sm rounded-full"
      :class="status ? 'bg-green-200' : 'bg-red-200'"
      >{{ textLabel }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    textLabel() {
      if (this.status) {
        return 'Activo'
      } else {
        return 'Desactivado'
      }
    },
  },
}
</script>
