<template>
  <form @submit.prevent="createTariffData">
    <!-- HEADER -->
    <div
      v-if="title || description"
      class="mt-3 flex justify-between items-center text-left"
    >
      <div>
        <h2 class="text-lg">
          <b>{{ title }}</b>
        </h2>
        <p class="text-gray-500">{{ description }}</p>
      </div>
      <StatusLabel v-if="detailsView || editView" :status="form.activo" />
    </div>
    <div>
      <BaseInput
        type="number"
        label="Código de Registro"
        v-model="form.codigo_registro"
        ref="codigo_registro"
        placeholder=""
        autocomplete="off"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Código crédito"
        :selectOptions="formOptions.optionsDefaultCredit"
        v-model="form.codigo_credito"
        ref="codigo_credito"
        :required="true"
        :disabled="detailsView"
      />

      <BaseSelect
        label="Apertura Digital"
        :selectOptions="formOptions.optionsDigitalOpening"
        v-model="form.apertura_digital"
        ref="apertura_digital"
        :required="true"
        :disabled="detailsView"
      />
    </div>
    <!-- CAPTURA UNIT -->
    <div
      v-if="!!form.unidad_captura.length || !detailsView"
      class="mt-6 mb-4 text-left"
    >
      <h2 class="text-lg"><b>Unidades de captura</b></h2>
      <p class="text-gray-500">
        Ingrese la información de la unidad de captura
      </p>
    </div>
    <div
      v-if="!!form.unidad_captura.length || !detailsView"
      class="p-4 bg-gray-50 rounded-lg"
    >
      <div v-if="!detailsView" class="mb-3 flex justify-end">
        <Button type="button" styles="secondary" @click.native="addCaptureUnit">
          Agregar unidad de captura
        </Button>
      </div>
      <div
        v-for="(unit, index) in form.unidad_captura"
        :ref="`unidad_captura-${index}`"
        :key="`uk-${index}`"
        class="px-3 pb-6 mb-3 flex flex-col gap-3 border rounded-lg bg-white"
      >
        <BaseInput
          type="text"
          label="Código de registro de unidad de captura"
          v-model="unit.codigo_registro_uc"
          :ref="`codigo_registro_uc-${index}`"
          placeholder=""
          autocomplete="off"
          :disabled="detailsView"
        />

        <BaseSelect
          label="Características del crédito"
          :selectOptions="formOptions.optionsOperationOrServiceCredit"
          v-model="unit.caracteristica_credito"
          :ref="`caracteristica_credito-${index}`"
          :disabled="detailsView"
          :required="true"
        />

        <BaseInput
          type="number"
          label="Costo"
          v-model="unit.costo"
          :ref="`costo-${index}`"
          placeholder=""
          autocomplete="off"
          :disabled="detailsView"
        />
        <BaseInput
          type="number"
          step="0.01"
          label="Tasa (%)"
          v-model="unit.tasa"
          :ref="`tasa-${index}`"
          placeholder=""
          autocomplete="off"
          :disabled="detailsView"
        />

        <BaseSelect
          label="Código aseguradora"
          :selectOptions="formOptions.optionsInsuranceEntities"
          :ref="`entidad_cod_id-${index}`"
          v-model="unit.entidad_cod_id"
          :disabled="detailsView"
        />
        <BaseSelect
          label="Observaciones"
          :selectOptions="formOptions.optionsObservationCredit"
          v-model="unit.observaciones"
          :ref="`observaciones-${index}`"
          :disabled="detailsView"
        />
        <div
          v-if="!detailsView && form.unidad_captura.length > 0 && !unit.activo"
          class="flex justify-end"
        >
          <Button
            type="button"
            styles="secondary"
            :invert="true"
            @click.native="deleteCaptureUnit(index)"
          >
            Eliminar
          </Button>
        </div>
      </div>
    </div>
    <!-- Start Disable Section -->
    <DisableTariff
      v-if="editView && form.desactivable"
      :format-type="formatType"
    />
    <!-- End disable section -->
    <div v-if="feedback">
      <p class="mt-8 text-xs text-center text-red-500">{{ feedback }}</p>
    </div>
    <div
      class="w-full mt-8 mr-auto flex flex-col lg:flex-row-reverse lg:justify-end gap-4"
    >
      <Button
        v-if="detailsView"
        type="button"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
        @click.native="
          $router.replace({ path: 'edit', append: true, query: $route.query })
        "
      >
        Editar
      </Button>
      <Button
        v-if="!editView && !detailsView"
        type="submit"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
      >
        Crear
      </Button>
      <Button
        v-if="editView"
        type="button"
        styles="secondary"
        :block="true"
        :loading="loading"
        :disable="loading"
        @click.native="updateTariffData"
      >
        Actualizar
      </Button>
      <Button
        type="button"
        :danger="true"
        :invert="true"
        :block="true"
        :disable="loading"
        @click.native="$router.go(-1)"
      >
        Volver
      </Button>
    </div>
  </form>
</template>
<script>
import {
  CREDIT_HEADER,
  CREDIT_UNIT_CAPTURE,
} from '@/components/Dashboard/Tariffs/objects/tariffProduct.js'
import formMixin from '@/components/Dashboard/Tariffs/FormTariff/formMixin'
import DisableTariff from '@/components/Dashboard/Tariffs/FormTariff/DisableTariff.vue'
import StatusLabel from '@/components/Dashboard/Tariffs/FormTariff/StatusLabel.vue'

export default {
  props: {
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
    detailsView: {
      default: false,
      type: Boolean,
    },
    editView: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [formMixin],
  components: { DisableTariff, StatusLabel },
  data() {
    return {
      loading: false,
      feedback: null,
      formatType: 3,
      form: {
        ...JSON.parse(JSON.stringify(CREDIT_HEADER)),
      },
    }
  },
  methods: {
    addCaptureUnit() {
      this.form.unidad_captura.unshift({ ...CREDIT_UNIT_CAPTURE })
    },
  },
}
</script>
