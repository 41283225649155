export const DEPOSIT_UNIT_CAPTURE = {
  codigo_registro_uc: '',
  operacion_servicio: '',
  canal: '',
  num_oper_serv_cuota_manejo: '',
  costo_fijo: '',
  costo_prop_oper_serv: '',
  observaciones: '',
}
export const DEPOSIT_HEADER = {
  // header
  activo: '',
  desactivable: false,
  codigo_registro: '',
  nombre_comercial: '',
  tipo_producto: '',
  apertura_digital: '',
  num_clientes_unicos: '',
  cuota_manejo: '',
  grupo_poblacional: '',
  obs_cuota_manejo: '',
  ingresos: '',
  cuenta_ahorros_1: '',
  cuenta_ahorros_2: '',
  cuenta_ahorros_3: '',
  tarjeta_debito_1: '',
  tarjeta_debito_2: '',
  tarjeta_debito_3: '',
  //   unit capture
  unidad_captura: [],
  // unidad_captura: [{ ...DEPOSIT_UNIT_CAPTURE }],
}

export const CREADIT_CARD_UNIT_CAPTURE = {
  //   unit capture

  codigo_registro_uc: '',
  operacion_servicio: '',
  canal: '',
  costo_fijo: '',
  costo_fijo_maximo: '',
  costo_prop_oper_serv: '',
  costo_prop_max_oper_serv: '',
  tasa: '',
  tasa_maxima: '',
  entidad_cod_id: '',
  observaciones: '',
}

export const CREDIT_CARD_HEADER = {
  // header
  activo: '',
  desactivable: false,
  codigo_registro: '',
  nombre_comercial: '',
  apertura_digital: '',
  num_clientes_unicos: '',
  franquicia: '',
  cuota_manejo: '',
  obs_cuota_manejo: '',
  cuota_manejo_maxima: '',
  grupo_poblacional: '',
  cupo: '',
  servicio_gratuito_1: '',
  servicio_gratuito_2: '',
  servicio_gratuito_3: '',
  //   unit capture
  unidad_captura: [],
  // unidad_captura: [{ ...CREADIT_CARD_UNIT_CAPTURE }],
}

export const CREDIT_UNIT_CAPTURE = {
  codigo_registro_uc: '',
  caracteristica_credito: '',
  costo: '',
  tasa: '',
  entidad_cod_id: '',
  observaciones: '',
}

export const CREDIT_HEADER = {
  // header
  activo: '',
  desactivable: false,
  codigo_registro: '',
  codigo_credito: '',
  apertura_digital: '',
  //   unit capture
  unidad_captura: [],
  // unidad_captura: [{ ...CREDIT_UNIT_CAPTURE }],
}
